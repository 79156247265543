import { withStyles } from "@material-ui/core/styles";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import React, { useEffect, useState } from "react";
import { defaultFont } from "../../MUI_Kit/assets/jss/material-dashboard-pro-react";
import semverValid from "semver/functions/valid";
import { APP_VERSION } from "../../variables/staticValue";
import semverLt from "semver/functions/lt";
import semver from "semver";
import { Trans } from "@lingui/macro";
import { Button } from "@doopage/react-ui-kit";
import { successColor, warningColor } from "../../variables/color";
import { getVersionWithoutBuildNumber } from "../../helper/helper";

const snackbarContentStyle = {
    root: {
        ...defaultFont,
        borderRadius: "4px",
        backgroundColor: 'white',
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        wide: 400,
        boxShadow:
            "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255, 0.2)",
    },

    action: {
        margin: 0,
        padding: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'end'
    },

    message: {
        width: '100%'
    }

};

const redirectToVersion= (version) => {
    window.location.href = window.location.origin + "/" + getVersionWithoutBuildNumber(version)
}

const sortVersionsDesc = (versions) =>  {
    return versions.sort((a, b) => semver.lt(b, a) ? -1 : 1);
}

const getRCVersions = async() => {
    const timeStampInSecond = Math.floor(Date.now() / 1000);
    const url = `/0.0.0-rc/versions.json?cachebuster=${timeStampInSecond}`;

    try {
        const response = await fetch(window.location.origin + url);
        if (response.ok) {
            const versions = await response.json();
            return sortVersionsDesc(versions.map(v => {
                if (v.includes("rc")) {
                    return v;
                }
                return v + "-rc";
            }));
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error fetching RC versions:", error);
        return [];
    }
};

const getBetaVersions = async() => {
    const timeStampInSecond = Math.floor(Date.now() / 1000);
    const url = `/0.0.0-beta/versions.json?cachebuster=${timeStampInSecond}`;

    try {
        const response = await fetch(window.location.origin + url);
        if (response.ok) {
            const versions = await response.json();
            return sortVersionsDesc(versions.map(v => {
                if (v.includes("beta")) {
                    return v;
                }
                return v + "-beta";
            }));
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error fetching Beta versions:", error);
        return [];
    }
};

const getMainVersions = async() => {
    const timeStampInSecond = Math.floor(Date.now() / 1000);
    const url = `/0.0.0/versions.json?cachebuster=${timeStampInSecond}`;

    try {
        const response = await fetch(window.location.origin + url);
        if (response.ok) {
            const versions = await response.json();
            return sortVersionsDesc(versions);
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error fetching Beta versions:", error);
        return [];
    }
};


const CustomSnackbarContent = withStyles(snackbarContentStyle)(SnackbarContent)
 const UpdateVersionModal = () => {
    const [open, setOpen] = useState(false)
     const [newVersion, setNewVersion] = useState('')
     const [isBeta, setIsBeta] = useState(false)

     const checkVersion = async () => {

         const mainVersions = await getMainVersions();
         const rcVersions = await getRCVersions();
         const latestMainVersion = mainVersions?.[0] || "";
         const latestRCVersion = rcVersions?.[0] || "";

         if (semverValid(latestMainVersion) && semverLt(APP_VERSION, latestMainVersion)) {
             setNewVersion(latestMainVersion)
             setIsBeta(false)
             setOpen(true)
             return;
         }

         if(semverValid(latestRCVersion) && semverLt(APP_VERSION, latestRCVersion)) {
             setNewVersion(latestRCVersion)
             setIsBeta(true)
             setOpen(true)
         }
     }

     useEffect(() => {
         checkVersion()
     }, [])

     const renderBody = () =>  {
         if(isBeta) {
             return (
                 <div className="flex flex-row  items-center">
                     <i className="fas fa-gift text-[28px]" style={{ marginRight: 20 }} /><Trans>Có phiên bản thử nghiệm mới</Trans> {newVersion}</div>
             );
         }
         return (
             <div className="flex flex-row  items-center"> <i className="fas fa-gift text-[28px] text-[#555555]" style={{ marginRight: 20 }}/> <Trans>Có phiên bản chính thức mới</Trans> {newVersion}</div>
         );
     }

     const renderActions = () => {
         return (
             <div>
                 {
                     isBeta  ?  <>
                             <Button  simple onClick={() => {
                                 redirectToVersion(newVersion)
                             }} >
                                 <Trans>Dùng thử</Trans>
                             </Button>
                             <Button color="info"  onClick={() => setOpen(false)} >
                                 <Trans>Bỏ qua</Trans>
                             </Button>
                         </> :
                         <>
                             <Button  color="danger" simple onClick={() => setOpen(false)}>
                                 <Trans>Để sau</Trans>
                             </Button>
                             <Button color="info"  onClick={() =>  redirectToVersion(newVersion)}>
                                 <Trans>Cập nhật</Trans>
                             </Button>
                         </>
                 }
             </div>
         );
     }
    return <div>
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
        >
            <CustomSnackbarContent
                style={{
                    width: 350,
                    border: '3px solid',
                    borderColor: isBeta ? warningColor :successColor
                }}
                message={
                    renderBody()
                }
                action={renderActions()}
            />
        </Snackbar>

    </div>
}

export default UpdateVersionModal
