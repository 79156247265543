import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import withStyles from "@material-ui/core/styles/withStyles";
import AddAlert from "@material-ui/icons/AddAlert";
import Network from "addOnComponent/Network";
import TouristIntro from "addOnComponent/TouristIntro/TouristIntro";
import { setState } from "config/Core";
import { GA_ID } from "config/Env";
import { getObjectFromParams } from "helper/helper";
import buttonStyle from "MUI_Kit/assets/jss/material-dashboard-pro-react/components/buttonStyle";
import tooltipsStyle from "MUI_Kit/assets/jss/material-dashboard-pro-react/components/tooltipsStyle";
import customCheckboxRadioSwitch from "MUI_Kit/assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import customSelectStyle from "MUI_Kit/assets/jss/material-dashboard-pro-react/customSelectStyle";
import dashboardStyle from "MUI_Kit/assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "MUI_Kit/assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import Snackbar from "MUI_Kit/components/Snackbar/Snackbar";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Analytics from "react-router-ga";
import { compose } from "redux";
import { PrivateRoute } from "router/components/PrivateRoute";
import { appRoutes } from "router/routes";
import DV from "variables/DV";
import { ALERT_ERROR_SHOW_TIME, ALERT_SHOW_TIME, LIST_DOMAIN } from "variables/staticValue";
import { loadLanguage } from "../../config/LinguiConfig";
import LocalStorage from "../../config/LocalStorage";
import { closeAll } from "../../redux/actionCreator/actionSelectInbox";
import "../../indexDb";
import { initIndexDB } from "../../indexDb";
import UpdateVersionModal from "../../modal/UpdateVersionModal/UpdateVersionModal";

const styles = (theme) => ({
    ...dashboardStyle,
    ...loginPageStyle,
    ...buttonStyle,
    ...customCheckboxRadioSwitch,
    ...tooltipsStyle,
    ...customSelectStyle,
    paper: {
        borderRadius: 20,
    },
});

const switchRoutes = (
    <Switch>
        {appRoutes.map((route, key) => {
            if (route.private)
                return (
                    <PrivateRoute
                        path={route.path}
                        component={route.component}
                        key={key}
                    />
                );

            return <Route exact key={key} {...route} />;
        })}
    </Switch>
);

class App extends Component {
    constructor(props) {
        super(props);
        const language = LocalStorage.getLang();

        initIndexDB();
        loadLanguage(language);

        let domain = window.location.href.toString();
        DV.partner = Object.keys(LIST_DOMAIN).find((key) =>
            domain.includes(key),
        );
        DV.classes = this.props.classes;
        DV.params = getObjectFromParams(window.location.search);
        DV.url = window.location.pathname


        if (DV.params.doopage_fake_owner_id) {
            LocalStorage.setFakeOwnerId(DV.params.doopage_fake_owner_id);
            props.closeAllInbox();
        }
        if (DV.params.doopage_fake_owner_token) {
            LocalStorage.setToken(DV.params.doopage_fake_owner_token);
            props.closeAllInbox();
        }
        if (DV.params.token) {
            LocalStorage.setToken(DV.params.token);
            props.closeAllInbox();
        }

        this.state = {
            notifyMessage: "",
            notifyColor: "success",
        };
    }

    hideNotify = () => {
        this.setState({ notifyMessage: "" });
    };

    showNotify = async(msg, color = "success", second = -1) => {
        if (second < 0) {
            second =
                color === "danger" ? ALERT_ERROR_SHOW_TIME : ALERT_SHOW_TIME;
        }
        await setState(this)({
            notifyMessage: msg,
            notifyColor: color,
        });
        setTimeout(this.hideNotify, second * 1000);
    };


    async componentDidMount() {
        DV.showNotify = this.showNotify;
    }

    componentWillUnmount() {
        DV.showNotify = () => {
        };
    }

    render() {
        const { currentCompany } = this.props;
        let logo = window.location.origin + LIST_DOMAIN[DV.partner].indexLogo;
        let title = LIST_DOMAIN[DV.partner].indexTitle;

        return (
            <I18nProvider i18n={i18n}>
                <UpdateVersionModal/>
                <div
                    style={{ height: "100dvh", overflowY: "hidden" }}
                    key={currentCompany.id}
                >
                    <Helmet>
                        <title>{title}</title>
                        <link rel="shortcut icon" href={logo} />
                    </Helmet>
                    <Network polling={false} />
                    <Snackbar
                        place="tr"
                        icon={AddAlert}
                        color={this.state.notifyColor}
                        message={this.state.notifyMessage || ""}
                        open={!!this.state.notifyMessage}
                        closeNotification={this.hideNotify}
                        close
                    />
                    <BrowserRouter>
                        <Analytics id={GA_ID}>{switchRoutes}</Analytics>
                    </BrowserRouter>
                    <TouristIntro
                        ref={(ref) => (this.tour = ref)}
                        reloadInboxList={this.reloadInboxList}
                    />
                </div>
            </I18nProvider>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        inboxList: state.inboxList.data,
        notifyMessage: state.notifyMessage,
        notifyColor: state.notifyColor,
        currentCompany: state.currentCompany,
        showWaringPaymentModal: state.showWaringPaymentModal,
        company: state.company,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeAllInbox: () => dispatch(closeAll()),
    };
};
export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(App);
